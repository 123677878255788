.bvn-image {
  .liveness-img {
    width: calc(100% + 4rem);
    margin-inline: -2rem;

    img {
      max-height: 30rem;
      margin: 0 auto;
    }
  }
}
