.v-step {
  --back-button-height: 0rem;

  .mobile-back-button-present {
    --back-button-height: 6.2rem;

    .mobile-wrapper {
      padding-top: 2rem;
    }
  }

  .mobile-back-button-wrapper {
    width: 100%;
    background-color: #fff;
    left: 0;
    padding: var(--padding-inline-space);
    height: var(--back-button-height);
  }
  .mobile-back-button {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    color: #000;
    gap: 1rem;
    font-weight: 500;
    width: 100%;
    max-width: 20rem;

    .bordered {
      width: 3rem;
      height: 3rem;
      border: 1px solid #e5e5e5;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg path {
        stroke: #676767;
      }
    }
  }

  .mobile-wrapper {
    display: grid;
    place-items: center;

    padding: var(--padding-inline-space);
    padding-top: 0;
    // max-height: calc(100vh - var(--back-button-height));
    // max-height: calc(100svh - var(--back-button-height));
    // width: calc(100vw - 10vw);
    // width: calc(100svw - 10svw);
    overflow-y: scroll;
    padding-bottom: 2rem;
    background-color: #fff;

    -ms-overflow-style: none;
    scrollbar-width: none;

    & > * {
      scroll-snap-align: start;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .verification__step--one {
      padding-bottom: 2rem;

      .btn-wrap--x {
        padding-bottom: 2rem;
      }
    }
  }

  .mobile-offset-top {
    margin-top: 5rem;
  }

  @media only screen and (min-width: 576px) {
    &__sm {
      display: none;
    }
  }

  @media not all and (min-width: 576px) {
    &__modal {
      display: none;
    }
  }
}

.identity-verification {
  .raven-modal-content-wrap {
    min-height: 55rem;
  }
}
