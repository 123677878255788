.bvn-disallowed {
  display: flex;
  align-items: center;
  gap: 1.2rem;

  &__icons {
    --icon-box-dimensions: 4rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 30px;
    background: var(--icon-box-bg);
    width: var(--icon-box-dimensions);
    height: var(--icon-box-dimensions);

    .bvn-disallowed-main-icon {
      margin: 0 auto;
    }
  }

  &-icon {
    position: absolute;
    inset: 0;
  }

  &-text {
    color: #333;
    font-size: 1.4rem;
    font-weight: 500;
  }
}

.bvn-allowed-section {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 2rem;
}
