.vst-six {
  padding-bottom: 10rem;

  &__uploading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    text-align: center;
  }

  &__status {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    max-width: 70%;
    margin-inline: auto;
  }
}
