.new-layout-bvn-nin-wrapper-content-reuse {
  height: 70vh;
  //   background-color: red;
  width: 100%;
  padding-top: 52%;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 48em) {
    height: 95vh;
    padding-top: 28rem;
  }

  .bg-img-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40%;

    .img-wrap-box {
      width: 100%;
      height: 100%;

      .img-box {
        width: 100%;
        height: 100%;

        .img,
        svg {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .text-decription-btn-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    .text-wrap-box {
      flex: 1;
      //   background-color: red;
      overflow-y: scroll;
      display: grid;
      place-items: center;
      padding-bottom: 3rem;

      &::-webkit-scrollbar {
        display: none;
      }

      .title-text-comp-reusable-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: max-content;
        height: max-content;

        .title {
          color: rgba(51, 51, 51, 1);
          font-size: 2.4rem;
          font-weight: 600;
          text-align: center;
          margin-bottom: 0.5rem;
        }

        .text {
          text-align: center;
          color: rgba(100, 100, 100, 1);
          font-size: 1.45rem;
          line-height: 2rem;
        }
      }
    }

    .btn-wrap-box {
      margin-top: auto;
      width: 100%;
      min-height: 6rem;
      //   background-color: blue;
    }
  }
}

.new-layout-bvn-nin-wrapper-content-verified-reuse {
  .bg-img-box {
    height: 50%;
    display: grid;
    place-items: center;

    &::after {
      content: "";
      position: absolute;
      bottom: -1px; /* Adjust to align perfectly */
      left: 0;
      width: 100%;
      height: 50px; /* Depth of concave cut */
      background-color: #ffffff; /* Match the background */
      clip-path: ellipse(60% 100% at 50% 100%);
    }

    & > * {
      position: relative;
      z-index: 3;
    }

    .img-wrap-box {
      width: 50%;
      height: 50%;

      @media only screen and (max-width: 48em){
        width: 50%;
        height: 50%;
      }
      // background-color: red;

      .img-box {
        width: 100%;
        height: 100%;

        .img,
        svg {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
