.new-layout-bvn-nin-wrapper-content-reuse {
  height: 70vh;
  width: 100%;
  padding-top: 52%;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 48em) {
  .new-layout-bvn-nin-wrapper-content-reuse {
    height: 95vh;
    padding-top: 28rem;
  }
}
.new-layout-bvn-nin-wrapper-content-reuse .bg-img-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40%;
}
.new-layout-bvn-nin-wrapper-content-reuse .bg-img-box .img-wrap-box {
  width: 100%;
  height: 100%;
}
.new-layout-bvn-nin-wrapper-content-reuse .bg-img-box .img-wrap-box .img-box {
  width: 100%;
  height: 100%;
}
.new-layout-bvn-nin-wrapper-content-reuse .bg-img-box .img-wrap-box .img-box .img,
.new-layout-bvn-nin-wrapper-content-reuse .bg-img-box .img-wrap-box .img-box svg {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.new-layout-bvn-nin-wrapper-content-reuse .text-decription-btn-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.new-layout-bvn-nin-wrapper-content-reuse .text-decription-btn-box .text-wrap-box {
  flex: 1;
  overflow-y: scroll;
  display: grid;
  place-items: center;
  padding-bottom: 3rem;
}
.new-layout-bvn-nin-wrapper-content-reuse .text-decription-btn-box .text-wrap-box::-webkit-scrollbar {
  display: none;
}
.new-layout-bvn-nin-wrapper-content-reuse .text-decription-btn-box .text-wrap-box .title-text-comp-reusable-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: -moz-max-content;
  width: max-content;
  height: -moz-max-content;
  height: max-content;
}
.new-layout-bvn-nin-wrapper-content-reuse .text-decription-btn-box .text-wrap-box .title-text-comp-reusable-box .title {
  color: rgb(51, 51, 51);
  font-size: 2.4rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0.5rem;
}
.new-layout-bvn-nin-wrapper-content-reuse .text-decription-btn-box .text-wrap-box .title-text-comp-reusable-box .text {
  text-align: center;
  color: rgb(100, 100, 100);
  font-size: 1.45rem;
  line-height: 2rem;
}
.new-layout-bvn-nin-wrapper-content-reuse .text-decription-btn-box .btn-wrap-box {
  margin-top: auto;
  width: 100%;
  min-height: 6rem;
}

.new-layout-bvn-nin-wrapper-content-verified-reuse .bg-img-box {
  height: 50%;
  display: grid;
  place-items: center;
}
.new-layout-bvn-nin-wrapper-content-verified-reuse .bg-img-box::after {
  content: "";
  position: absolute;
  bottom: -1px; /* Adjust to align perfectly */
  left: 0;
  width: 100%;
  height: 50px; /* Depth of concave cut */
  background-color: #ffffff; /* Match the background */
  -webkit-clip-path: ellipse(60% 100% at 50% 100%);
          clip-path: ellipse(60% 100% at 50% 100%);
}
.new-layout-bvn-nin-wrapper-content-verified-reuse .bg-img-box > * {
  position: relative;
  z-index: 3;
}
.new-layout-bvn-nin-wrapper-content-verified-reuse .bg-img-box .img-wrap-box {
  width: 50%;
  height: 50%;
}
@media only screen and (max-width: 48em) {
  .new-layout-bvn-nin-wrapper-content-verified-reuse .bg-img-box .img-wrap-box {
    width: 50%;
    height: 50%;
  }
}
.new-layout-bvn-nin-wrapper-content-verified-reuse .bg-img-box .img-wrap-box .img-box {
  width: 100%;
  height: 100%;
}
.new-layout-bvn-nin-wrapper-content-verified-reuse .bg-img-box .img-wrap-box .img-box .img,
.new-layout-bvn-nin-wrapper-content-verified-reuse .bg-img-box .img-wrap-box .img-box svg {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}/*# sourceMappingURL=NewFittedLayout.css.map */