.bvn-two {
  --border-x: 1px;

  .bvn-hint {
    margin-top: 2.4rem;
    display: flex;
    align-items: flex-start;
    gap: 1rem;

    .accent {
      color: var(--primary-orange-100, #ea872d);
      font-weight: 600;
    }

    .hint-icon {
      max-width: 2rem;
      width: 100%;
      margin-top: 0.4rem;
    }
  }

  .footer-wrap {
    padding-top: 16rem;
  }
}
