.verification__step--one {
  // background-color: red;
  // position: relative;
  // z-index: 10;

  .hero-banner {
    height: var(--header-height);
    position: absolute;
    width: 100%;
    background-size: cover;
    background-position: center top;
    top: 0;
    left: 0;
    // background-color: red !important;
    // display: none;

  }

  .verification-title {
    margin-top: calc(var(--header-height) - (var(--top-panel-height)) + 1.6rem);
    margin-bottom: 3rem;
    max-width: 30ch;
    // background-color: red;
  }

  @media not all and (min-width: 576px) {
    justify-self: start;
    align-self: self-start;
    height: 100%;

    .hero-banner {
      width: 100vw;
      height: 20rem;
      // height: 35%;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      // background-color: red !important;
      // display: none;
    }
    .verification-title {
      margin-top: 4rem;
      // padding-inline: var(--padding-inline-space);
    }
  }

  .phone-illustration {
    border-radius: 1rem;
    border: 3.321px solid var(--outline-color);
    display: flex;
    max-width: 10rem;
    width: 100%;
    min-height: 14rem;
    padding: 3.5rem 2.5rem 2.5rem;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 1.4rem;
    margin: var(--y-gap) auto;

    .bars-group {
      --bars-width: 4rem;

      & > * + * {
        margin-top: 0.6rem;
      }

      .bars {
        width: var(--bars-width);
        height: 0.4rem;
        border-radius: 2rem;
        background: var(--outline-color);
        margin-inline: auto;

        &--two {
          width: calc(var(--bars-width) / 1.5);
        }
      }
    }
  }

  .verification-info-section {
    margin-bottom: 3rem;
  }

  // .btn-wrap--x{
  //   position: fixed;
  //   z-index: 10;
  //   top: 100%;
  //   left: 50%;
  //   transform: translateX(-50%);
  // }
}
