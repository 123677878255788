.modal-xyz {
  .raven-modal-content-wrap {
    .close-box {
      border-bottom: 1px solid var(--raven-black-100, #eee);
      z-index: 1;
      height: var(--top-panel-height);
      display: flex;

      figure {
        margin-left: auto;
      }
    }
  }
}

.content-height-55 .raven-modal-content-wrap {
  min-height: 55rem;
}
