.aws-liveness-rvp {
  min-height: 40vh;
  min-height: 40svh;
  & * {
    font-family: var(--app-font-family);
  }
}

.rvp-aws-liveness-loader {
  min-height: 40vh;
  min-height: 40svh;
  display: grid;
  place-items: center;

  img {
    height: 15rem;
    width: 15rem;
  }
}
.amplify-liveness-camera-module {
  border: 1px solid #fff;

  &:before {
    position: absolute;
    content: "";
    height: 105%;
    width: 100vw;
    background-color: #fff;
  }
}
