@import "fonts";

:root {
  // base starts here ----
  --base-white: #ffffff;
  --base-black: #020202;
  --base-background-white: #f9f9f9;
  --base-background-black: #676767;

  // border color start here ----
  --border-green: #0b8376;
  --border-grey-dark: #676767;
  --border-grey-light: #cccccc;
  --border-light: #f7f8f7;

  // primary start here ------
  --primary-green: #0b8376;
  --primary-deep-green: #014345;
  --primary-blue: #476885;
  --primary-orange: #ea872d;
  --primary-purple: #755ae2;
  --primary-black: #020202;

  // accent starts here -------
  --raven-black-800: #333;
  --raven-black-600: #646464;
  --raven-black-100: #eee;

  --primary-green-20: #f2fffa;

  --grey600: #646464;

  --grey-700: #515151;

  --grey-50: var(var(--border-grey-light));

  --outline-color: #755ae2;

  --y-gap: 3.6rem;
  --header-height: 28rem;
  --top-panel-height: 6rem;
  --padding-inline-space: 1.6rem;

  --modal-width: 43rem;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  font-size: 62.5% !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  @media only screen and (max-width: 75em) {
    font-size: 62.5% !important;
  }
}
body {
  font-weight: 400;
  position: relative;
  overscroll-behavior: none;
  overflow-y: scroll;
  overflow-x: hidden;
  color: var(--accent-grey-800);

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }

  --app-font-family: "br_firma", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
html,
body {
  height: 100%;
}
body {
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
  font: unset;
}
#root,
#__next {
  isolation: isolate;
}

.fullBleed {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.verification {
  font-size: 1.4rem;

  & &-wrapper {
    .close-box {
      border-bottom: 1px solid var(--raven-black-100, #eee);
      z-index: 1;
      height: var(--top-panel-height);

      figure {
        margin-left: auto;
      }

      svg {
        margin-left: auto;
        display: block;
        width: fit-content;
      }
    }

    .modal-parent-wrap .raven-modal-content-wrap .button-wrap {
      background-color: transparent;
    }
  }

  &-modal {
    .raven-modal-content-wrap {
      max-width: var(--modal-width);
      width: 100%;

      .content {
        max-height: calc(90vh - var(--top-panel-height));
        overflow-y: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        // background-color: red;
        // height: 100vh !important;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      @media only screen and (max-width: 576px) {
        max-width: 95%;
      }
    }
  }

  &__back-button {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    translate: 0 100%;
    font-size: 1.4rem;
    z-index: 1;

    svg {
      transition: all 300ms ease-out;
      height: 2.2rem;
      width: 2.2rem;
    }

    &:hover {
      svg {
        transform: translateX(-0.2rem);
      }
    }
  }

  &-info-section {
    & > * + * {
      margin-top: 2rem;
    }
  }

  &__content {
    padding-block: 0.4rem 1.4rem;
    // background-color: red;
    // flex: 1;
  }

  &-title {
    font-size: 1.9rem;
    font-weight: 600;
    line-height: 1.6;
    color: var(--raven-black-800, #333);
  }

  &-header {
    color: var(--raven-black-600, #646464);

    & > * + * {
      margin-top: 1.4rem;
    }
  }

  .w--full {
    width: 100%;
  }

  .btn-reset {
    border: transparent;
    cursor: pointer;
    background-color: transparent;
  }

  .btn-outline {
    color: var(--outline-color);
    background-color: transparent;
    border: 1px solid var(--outline-color);
  }

  .btn-uploader {
    position: relative;

    .uploader-control {
      opacity: 0;
      position: absolute;
      inset: 0;
      cursor: pointer;
      color: transparent;

      input {
        display: none;
      }
    }

    .masked-input {
      opacity: 0;
      position: absolute;
      inset: 0;
      cursor: pointer;
      color: transparent;
    }
  }

  .space-y-40 > * + * {
    margin-top: 4rem;
  }
  .space-y-20 > * + * {
    margin-top: 2rem;
  }

  .text-center {
    text-align: center;
  }

  .mt-12 {
    margin-top: 1.2rem;
  }

  .qr-modal {
    max-height: 22rem;
    margin-inline: auto;
  }
}

.video-capture-enabled {
  background-color: #000;
  overflow: hidden !important;
  padding-bottom: 0;
  height: 100vh;
}

.vst-qr {
  max-width: 20rem;
  margin: auto;
}

.two-grid__buttons {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 2rem;
  width: 100%;
}

.centered-x {
  width: auto;
  width: fit-content;
  margin: 0 auto;
}

.bvn-liveness-check {
  max-height: calc(100svh - 10svh); 
  display: grid;
  place-items: center;
  .liveness-header {
    font-size: 1.55rem;
    font-weight: 600;
    color: var(--grey-800, #333);
  }
}

.fz-base {
  font-size: 1.6rem;
}

.xyz {
  position: fixed;
  z-index: 10000;
  background-color: red;
  font-size: 1.65rem;
}

.loader-spinner {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: unset;
  background-color: rgba(0, 0, 0, 0.3);
}
/** 
* https://cssloaders.github.io/
*/
.xyz-loader {
  width: 48px;
  height: 48px;
  border: 3px solid var(--outline-color);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: loader-rotate 1s linear infinite;
}

.loading-content {
  opacity: 0;
}

@keyframes loader-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.warning-illustration {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-block: 6rem;
}

.error-text::first-letter {
  text-transform: capitalize;
}

.switch-to-mobile-illustration {
  svg {
    width: 2.4rem;
    height: 2.4rem;
  }
}

.image--debug {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.fdm-loader-upload {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
  border-radius: 100%;

  display: grid;
  place-items: center;

  color: #fff;
  font-size: 4rem;
  font-weight: bold;
}

.fdm-special-loader {
  width: 2.5rem;
  aspect-ratio: 1;
  --c: no-repeat
    repeating-linear-gradient(90deg, #000 0 calc(100% / 7), transparent 0 calc(200% / 7));
  background: var(--c), var(--c), var(--c), var(--c);
  background-size: 140% 26%;
  animation: l25 0.75s infinite;
}
@keyframes l25 {
  0% {
    background-position: 0 calc(0 * 100% / 3), 100% calc(1 * 100% / 3), 0 calc(2 * 100% / 3),
      100% calc(3 * 100% / 3);
  }
  25% {
    background-position: 100% calc(0 * 100% / 3), 100% calc(1 * 100% / 3), 0 calc(2 * 100% / 3),
      100% calc(3 * 100% / 3);
  }
  50% {
    background-position: 100% calc(0 * 100% / 3), 0 calc(1 * 100% / 3), 0 calc(2 * 100% / 3),
      100% calc(3 * 100% / 3);
  }
  75% {
    background-position: 100% calc(0 * 100% / 3), 0 calc(1 * 100% / 3), 100% calc(2 * 100% / 3),
      100% calc(3 * 100% / 3);
  }
  100% {
    background-position: 100% calc(0 * 100% / 3), 0 calc(1 * 100% / 3), 100% calc(2 * 100% / 3),
      0 calc(3 * 100% / 3);
  }
}

.v-step__sm {
  height: 100vh;
  height: 100svh;
}

@media only screen and (max-width: 576px) {
  html body {
    min-height: 100vh;
    min-height: 100svh;
    // padding-bottom: 5rem;
  }

  .hide-for-mobile {
    display: none;
  }

  .verification-error-screen {
    .modal-parent-wrap {
      background-color: #fff !important;
    }

    .modal__close-div-wrap,
    .close-box {
      display: none !important;
    }
  }
}

@media only screen and (max-width: 48em) {
  .notification-container {
    .notification {
      margin: 0 !important;
      &.top-right {
        right: 0% !important;
        top: 0;
        left: 50%;
        width: 100%;

        transform: translateX(-50%);
      }
    }

    .toast-wrap {
      padding-right: 4rem !important;
    }

    &.top-right {
      right: 0% !important;
      top: 0;
      left: 50%;
      width: 100%;

      transform: translateX(-50%);
    }
  }
}

.notification {
  z-index: 1000;
}
