.vst-success {
  @media only screen and (max-width: 576px) {
    min-height: 47rem;
  }

  .success-illustration {
    max-width: 25.2rem;
    min-height: 22rem;
    margin: 0 auto;

    @media only screen and (max-width: 576px) {
      width: 24rem;
      height: 20rem;
      min-height: auto;
    }
  }
}
