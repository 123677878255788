.bvn-flow {
  .raven-modal-content-wrap {
    min-height: 55rem;
  }

  .v-bvn-success {
    .raven-modal-content-wrap {
      min-height: auto;
    }
  }

  .verification-header > * + * {
    margin-top: 0.8rem;
  }

  .bvn-back-button {
    color: #000;
    position: static;
    margin-bottom: 2rem;
    justify-self: start;
    transform: translate(0, 20px);

    .bordered {
      border-color: #e5e5e5;
    }
  }
}
