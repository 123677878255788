/** https://cssloaders.github.io/ */
.loader {
  width: var(--lds-size);
  height: var(--lds-size);
  border: var(--lds-loader-size) solid var(--lds-spinner-color);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
